import React from 'react';
import { Button } from '../..';
import { useApi } from '../../../hooks';
import { Tooltip2 } from '@blueprintjs/popover2';

const verificationSubmitUrl = `/api/verify/start`;
const verificationResumeUrl = `/api/verify/resume`;

const VerifyButton = ({
  isVerified,
  verificationRequired,
  hasPendingVerification,
  as = 'link',
  ...props
}: {
  isVerified: boolean;
  verificationRequired: boolean;
  hasPendingVerification: boolean;
  as?: 'link' | 'button';
  [x: string]: any;
}) => {
  const api = useApi();
  const [resumeUrl, setResumeUrl] = React.useState<string | null>(null);
  const defaultRedirectUrl = React.useMemo(() => window.location.href, []);

  const canVerifyIdentity = React.useMemo(() => {
    return !isVerified && verificationRequired && !hasPendingVerification;
  }, [isVerified, verificationRequired, hasPendingVerification]);

  const verificationButtonText = React.useMemo(() => {
    if (isVerified) return 'Verified';

    if (hasPendingVerification) return 'Pending';

    return 'Verify';
  }, [isVerified, hasPendingVerification]);

  const verificationButtonTooltip = React.useMemo(() => {
    if (!isVerified && hasPendingVerification)
      return 'Waiting for verification results';

    return '';
  }, [isVerified, hasPendingVerification]);

  const isDisabled = !canVerifyIdentity;

  const fetchClientSecret = async () => {
    const data = {
      return_url: defaultRedirectUrl,
      refresh_url: defaultRedirectUrl,
    };

    return await api.post(verificationSubmitUrl, data);
  };

  const handleClick = async (event: any) => {
    // Block native event handling.
    event.preventDefault();

    // Call your backend to create the VerificationSession.
    const { data } = await fetchClientSecret();

    return (window.location.href = data.verify_url);
  };

  React.useEffect(() => {
    api.get(verificationResumeUrl).then((res) => setResumeUrl(res?.data?.url));
  }, [api]);

  React.useEffect(() => {
    const handler = function (e: MessageEvent) {
      e.stopPropagation();

      console.log(e);
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  if (!verificationRequired || isVerified) {
    return null;
  }

  if (resumeUrl) {
    return (
      <Button
        theme={as === 'link' ? 'link' : 'primary'}
        onClick={() => window?.location?.replace(resumeUrl)}
      >
        Resume
      </Button>
    );
  }

  return (
    <Tooltip2 placement="auto" content={verificationButtonTooltip}>
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        theme={as === 'link' ? 'link' : isDisabled ? 'secondary' : 'primary'}
        {...props}
      >
        {verificationButtonText}
      </Button>
    </Tooltip2>
  );
};

export default VerifyButton;
