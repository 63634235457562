import { isEmpty } from 'lodash';
import React from 'react';
import { formatDistance } from 'date-fns';
import { Popover2 } from '@blueprintjs/popover2';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { useApi } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { Action, Table, Td, Th, Tr } from '../../../components';
import { BiBadgeCheck, BiErrorCircle } from 'react-icons/bi';

export const IdentityDocumentsList = ({
  docs,
  reload,
}: {
  docs: any[];
  reload: () => void;
}) => {
  const api = useApi();
  const toaster = useToaster();

  const redactFile = (id: any) => {
    return api.delete(`/api/verify/${id}/redact`).then(({ data }) => {
      reload();

      toaster.success({
        timeout: 10000,
        message: data.message,
      });
    });
  };

  return (
    <>
      <h6 className="pg-ComplianceSection__Title">
        Identity Verification Documents
      </h6>

      <div className="pg-ComplianceSection__Content">
        {isEmpty(docs) ? (
          <p className="pg-ComplianceSection__Content__Empty">
            No documents added yet
          </p>
        ) : (
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Verified</Th>
                <Th isAction></Th>
              </Tr>
            </thead>
            <tbody>
              {docs?.map((item: any) => (
                <Tr key={item.file_id}>
                  <Td>
                    <p>
                      <span>{item?.request_id} </span>

                      {item?.status === 'verified' ? (
                        <BiBadgeCheck size={18} color="green" />
                      ) : (
                        <BiErrorCircle size={18} color="red" />
                      )}
                    </p>
                  </Td>

                  <Td title={new Date(item.created_at).toString()}>
                    {formatDistance(new Date(item.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </Td>

                  <Td isAction>
                    <Popover2
                      minimal
                      position={Position.BOTTOM_RIGHT}
                      transitionDuration={-1}
                      content={
                        <Menu>
                          <MenuItem
                            text="Remove"
                            onClick={() => redactFile(item.verification_id)}
                          ></MenuItem>
                        </Menu>
                      }
                    >
                      <Action>
                        <IoEllipsisHorizontalSharp />
                      </Action>
                    </Popover2>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};
