import Avatar from 'react-avatar';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DataGridColumnsFunc } from '../../types';
import { MediaObject, Tag } from '../../components';
import { ContractorType, TaxForm } from '../../constants';
import { BiBadgeCheck, BiErrorCircle } from 'react-icons/bi';

export const ClientComplianceColumns: DataGridColumnsFunc = (args: any) => [
  {
    headerName: 'Contractor',
    field: 'name',
    minWidth: 200,
    flex: 1,
    renderCell: (params: GridRenderCellParams<String>) => (
      <MediaObject
        align="center"
        media={<Avatar size="36px" round name={params.row?.name} />}
      >
        {params.row?.name}
      </MediaObject>
    ),
  },
  {
    headerName: 'Type',
    field: 'isContractorEntity',
    type: 'singleSelect',
    width: 120,
    valueOptions: [
      { value: ContractorType.INDIVIDUAL, label: 'Individual' },
      { value: ContractorType.ENTITY, label: 'Entity' },
    ],
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.isContractorEntity
        ? ContractorType.ENTITY
        : ContractorType.INDIVIDUAL;
    },
    renderCell: (params: GridRenderCellParams<String>) => (
      <Tag>{params.row?.isContractorEntity ? 'Company' : 'Individual'}</Tag>
    ),
  },
  {
    headerName: 'Identity Verification',
    field: 'identityVerification',
    flex: 1,
    type: 'singleSelect',
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.row?.identityVerification) return 'Not verified';

      return (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={
            params.row?.identityVerification?.verification_session_url || '#'
          }
          className="TaxForm__Preview__link"
        >
          <span>{params.row?.identityVerification?.request_id} </span>

          {params.row?.identityVerification?.status === 'verified' ? (
            <BiBadgeCheck size={18} color="green" />
          ) : (
            <BiErrorCircle size={18} color="red" />
          )}
        </a>
      );
    },
  },
  {
    headerName: 'Uploaded Documents',
    field: 'documents',
    flex: 1,
    type: 'singleSelect',
    filterable: false,
    sortable: false,
    valueOptions: [
      { value: TaxForm.W9, label: 'W9' },
      { value: TaxForm.W8BEN, label: 'W8BEN' },
      { value: TaxForm.W8BENE, label: 'W8BENE' },
    ],
    valueGetter: (params: GridValueGetterParams<String[]>) => {
      return map(params.row.documents, 'key').join(', ');
    },
    renderCell: (params: GridRenderCellParams) => {
      if (isEmpty(params.row?.documents)) return 'None';

      return map(params.row?.documents, (doc) => (
        <a
          key={doc?.key}
          href={`${doc.file_url}?auth=${args?.token}`}
          target="_blank"
          rel="noopener noreferrer"
          className="TaxForm__Preview__link"
        >
          {doc?.key?.toUpperCase()}
        </a>
      ));
    },
  },
  {
    headerName: 'Missing Documents',
    field: 'missing-documents',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        params.row['missing-documents']
          ?.map((doc: string) => doc.toUpperCase())
          .join(', ') || 'None'
      );
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 120,
    type: 'singleSelect',
    valueOptions: ['Completed', 'Incomplete'],
    renderCell: (params: GridRenderCellParams) =>
      params?.row?.status === 'Completed' ? (
        <Tag status="success">Completed</Tag>
      ) : (
        <Tag status="danger">Incomplete</Tag>
      ),
  },
];
